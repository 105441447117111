import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import BrowseList from "../components/BrowseList"

export default ({ data }) => {
  const party = data.allPartyCsv.edges || []
  const partyName = party[0].node.fields.description
  const parliamentarians = party.map(p => p.node.parliamentarian) || []

  return (
    <Layout>
      <HeadMeta pageTitle={partyName} />
      <PageHeader>{partyName}</PageHeader>
      <Breadcrumb
        route={[
          { name: "Parliamentary Papers", href: "/" },
          { name: "Political Parties", href: "/parties/" },
          { name: partyName, href: `` },
        ]}
      />
      {parliamentarians.length > 0 && <BrowseList items={parliamentarians} />}
    </Layout>
  )
}

export const query = graphql`
  query($partyName: String!) {
    allPartyCsv(filter: { fields: { description: { eq: $partyName } } }) {
      edges {
        node {
          fields {
            description
            id
            parliamentarianId
          }
          parliamentarian {
            fields {
              name
              slug
            }
          }
        }
      }
    }
  }
`
